import React from "react"

import {
    Container,
    Profile,
    Avatar,
    UserData,
    Icons,
    MicIcon,
    HeadphoneIcon,
    SettingsIcon,
} from "./styles"

import jackie from "../../assets/images/avatars/Jackie.png"

export interface Props {
    avatar: string
}

const UserInfo: React.FC = () => {
    return (
        <Container>
            <Profile>
                <Avatar avatar={jackie} />
                <UserData>
                    <strong>Jackie Robinson</strong>
                    <span>#42</span>
                </UserData>
            </Profile>

            <Icons>
                <MicIcon />
                <HeadphoneIcon />
                <SettingsIcon />
            </Icons>
        </Container>
    )
}

export default UserInfo
