// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

// Your web app's Firebase configuration
const firebaseConfig2 = {
    apiKey: "AIzaSyCm-aaWNxJST7IodeBos2QZUtdj6HmjbZY",
    authDomain: "glime-dataloader.firebaseapp.com",
    projectId: "glime-dataloader",
    storageBucket: "glime-dataloader.appspot.com",
    messagingSenderId: "995945205533",
    appId: "1:995945205533:web:0492601dbabf821b981bf4",
}

// Initialize Firebase
const _app = initializeApp(firebaseConfig2)
const _db = getFirestore(_app)

const useFirestore = () => {
    return {
        db: _db,
    }
}

export { useFirestore }
