import styled from "styled-components"

export const LeftFloatBg = styled.img`
    position: absolute;
    top: 40px;
    left: 0px;
    height: 200px;
    z-index: 9999999;
`

export const RightFloatBg = styled.img`
    position: absolute;
    bottom: 40px;
    right: 0px;
    height: 200px;
    z-index: 9999999;
`

export const ModalView = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8);
    font-family: "Inter", sans-serif;
    font-weight: 400;
`

export const ModalContent = styled.div`
    background-color: #e2e2e2;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 520px;
    position: relative;
`

export const ModalBody = styled.div`
    background-color: #e2e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 16px;

    > p {
        font-size: 16px;
        line-height: 1.2;

        &:not(:last-of-type) {
            margin-top: 20px;
        }

        &:last-of-type {
            font-size: 14px;
            font-weight: 500;
            margin-top: 10px;
        }
    }
`

export const Logo = styled.img`
    margin: 22px 0px 0px 0px;
`

export const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column;
`

export const ModalTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: #111111;

    > small {
        margin-right: 5px;
        font-size: 18px;
    }

    > h1 {
        font-size: 36px;
    }
`

export const ModalButton = styled.button`
    background-color: #111111;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 40px;
    width: 100%;
`

export const LearnMore = styled.a`
    color: #434343;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
`
