import React from "react"

import ChannelButton from "../ChannelButton"

import { Container, Category, AddCategoryIcon } from "./styles"

type ChannelInfo = {
    name: string
    selected?: boolean
}

interface ChannelListProps {
    channels: ChannelInfo[]
}

const ChannelList: React.FC<ChannelListProps> = ({ channels }) => {
    return (
        <Container>
            <Category>
                <span>Text Channels</span>
                <AddCategoryIcon />
            </Category>

            {channels.map((channel) => (
                <ChannelButton
                    channelName={channel.name}
                    selected={channel.selected}
                />
            ))}
        </Container>
    )
}

export default ChannelList
