import styled from "styled-components"
import { Mic, Headset, Settings } from "styled-icons/material"

import { Props } from "."

export const Container = styled.div`
    grid-area: UI;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: var(--quaternary);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px;
`

export const Profile = styled.div`
    display: flex;
    align-items: center;
`

export const Avatar = styled.div<Props>`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: var(--gray);

    background-image: url("${(props) => props.avatar}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export const UserData = styled.div`
    margin-left: 8px;
    display: flex;
    flex-direction: column;

    > strong {
        font-size: 13px;
        color: var(--white);
        display: block;
    }

    > span {
        color: var(--gray);
        font-size: 13px;
    }
`

export const Icons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    > svg:not(:first-child) {
        margin-left: 7px;
    }
`

export const MicIcon = styled(Mic)`
    width: 20px;
    height: 20px;
    color: var(--white);
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
`

export const HeadphoneIcon = styled(Headset)`
    width: 20px;
    height: 20px;
    color: var(--white);
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
`

export const SettingsIcon = styled(Settings)`
    width: 20px;
    height: 20px;
    color: var(--white);
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
`
