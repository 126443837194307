import styled from "styled-components"

import { Props } from "."

export const Container = styled.div`
    grid-area: UL;
    display: flex;
    flex-direction: column;
    padding: 3px 6px 0 16px;
    background-color: var(--secondary);
    max-height: calc(100vh - 46px);
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--tertiary);
        border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: var(--secondary);
    }
`

export const Role = styled.span`
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    color: var(--gray);
`

export const User = styled.div`
    margin-top: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    > strong {
        margin-left: 13px;
        font-weight: 500;
        color: var(--white);
        opacity: 0.7;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    > span {
        margin-left: 9px;
        background-color: var(--discord);
        color: var(--white);
        border-radius: 4px;
        padding: 4px 5px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
    }
`

export const Avatar = styled.div<Props>`
    flex-shrink: 1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--primary);

    &.bot {
        background-color: var(--mention-detail);
    }

    background-image: url("${(props) => props.avatar}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export const Badge = styled.div`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 28px;
    border: 2px solid var(--quaternary);

    &.online {
        background-color: rgb(35, 165, 90);
    }

    &.offline {
        background-color: var(--tertiary);
    }
`
