import React, { useEffect } from "react"

import { Container, Avatar, Message, Header, Content } from "./styles"
import ReactMarkdown from "react-markdown"
export { Mention } from "./styles"

export interface Props {
    author: any
    date: string
    content: string | React.ReactElement | React.ReactNode
    hasMention?: boolean
    isBot?: boolean
    continuation?: boolean
    isHTML?: boolean
}

export interface MessageProps {
    avatar: string
}

const ChannelMessage: React.FC<Props> = ({
    author,
    date,
    content,
    hasMention,
    isBot,
    continuation,
    isHTML,
}) => {
    const formatHeader = () => {
        if (!continuation) {
            return (
                <Header>
                    <strong>{author.nickname}</strong>

                    {isBot && <span>Bot</span>}

                    <time>{date}</time>
                </Header>
            )
        } else {
            return <Header></Header>
        }
    }

    const mentionClass = hasMention ? "mention" : ""
    const headlessClass = continuation ? "headless" : ""

    let classes = `${mentionClass} ${headlessClass}`

    function cleanMessage(message: string) {
        return { __html: message }
    }

    return (
        <Container className={classes}>
            <div style={{ minWidth: "40px" }}>
                {!continuation && (
                    <Avatar
                        className={isBot ? "bot" : ""}
                        avatar={author.avatar}
                    />
                )}
            </div>

            <Message>
                {formatHeader()}
                {isHTML ? (
                    <Content>
                        <ReactMarkdown>{content as string}</ReactMarkdown>
                    </Content>
                ) : (
                    <Content>{content}</Content>
                )}
            </Message>
        </Container>
    )
}

export default ChannelMessage
