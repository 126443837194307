import React from "react"

import {
    Container,
    HashtagIcon,
    Title,
    Separator,
    Description,
    BellIcon,
    BookmarkIcon,
    PeopleIcon,
    FileIcon,
    HelpIcon,
    SearchIcon,
    InputContainer,
} from "./styles"

const ChannelInfo: React.FC = () => {
    return (
        <Container>
            <div className="channel-info">
                <HashtagIcon />
                <Title>support</Title>

                <Separator />

                <Description>
                    This is our main support channel, powered by Glime!
                </Description>
            </div>

            <div className="actions">
                <BellIcon />
                <BookmarkIcon />
                <PeopleIcon />

                <InputContainer>
                    <input type="text" placeholder="Buscar" />
                    <SearchIcon />
                </InputContainer>

                <FileIcon />
                <HelpIcon />
            </div>
        </Container>
    )
}

export default ChannelInfo
