import React from "react"
import { ReactElement, ReactNode } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import _ from "underscore"
import { Message } from "../../components/Layout"

export const splitLinesInParagraphs = (text: string) => {
    return text
        .split("\n")
        .filter((line) => line !== "")
        .map((line) => {
            // If the line is not a <pre><code> block, wrap it in <p> tags.
            if (
                !line.startsWith("<pre><code") &&
                !line.endsWith("</code></pre>")
            ) {
                line = '<p class="answer-content">' + line + "</p>"
            }
            return line
        })
        .join("\n")
}

function formatLanguageName(language: any) {
    // Capitalize the first letter of the language name.
    return language.charAt(0).toUpperCase() + language.slice(1)
}

export function convertMarkdownCodeBlocksToHtml(inputString: any) {
    // Matches markdown code blocks enclosed by triple backticks, with optional language specification.
    const regex = /```(\w+)?\n([^`]+)```/g

    // Replace the matched markdown code block with HTML <pre><code> tags.
    let outputString = inputString.replace(
        regex,
        function (match: any, language: any, code: any) {
            // Remove leading/trailing newline characters if present.
            code = code.replace(/^\n|\n$/g, "")

            // Convert any HTML special characters in the code to their corresponding entities.
            code = code
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")

            // If a language was specified, add it as a class to the <code> tag.
            let classAttr = language ? ` class="language-${language}"` : ""

            // Wrap the code in HTML <pre><code> tags.
            return "<pre>" + "<code" + classAttr + ">" + code + "</code></pre>"
        }
    )

    // Split the string by newline characters and wrap each line in <p> tags.
    outputString = outputString
        .split("\n")
        .filter((line: any) => line !== "")
        .map((line: any) => {
            // If the line is not a <pre><code> block, wrap it in <p> tags.
            if (
                !line.startsWith("<pre><code") &&
                !line.endsWith("</code></pre>")
            ) {
                line = '<p class="answer-content">' + line + "</p>"
            }
            return line
        })
        .join("\n")

    return outputString
}

export const messageToString = (
    message: string | React.ReactElement | React.ReactNode
): string => {
    const contentAsHtmlString = React.isValidElement(message)
        ? renderToStaticMarkup(message)
        : (message as string)

    const htmlAsStringWithScaped = contentAsHtmlString.replace(
        /<\/?[^>]+(>|$)/g,
        ""
    )

    return _.unescape(htmlAsStringWithScaped)
}
