import axios from "axios"
import { SimpleMessage } from "../../SimpleMessage"

export interface LLMOptions {
    top_k: number
    similarity_threshold: number
    model: string
}

const useLLM = () => {
    const fetchLLM = async (
        namespace: string,
        question: string,
        sessionId: string,
        history: SimpleMessage[],
        options: LLMOptions = {
            top_k: 5,
            similarity_threshold: 0.8,
            model: "gpt-4",
        }
    ) => {
        // call the backend using axios
        // return the response
        const response = await axios.post(
            "https://glimelab-bot-backend.onrender.com/sandbox",
            {
                ...options,
                namespace,
                question,
                sessionId,
                history,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )

        return response.data
    }

    return {
        fetchLLM,
    }
}

export { useLLM }
