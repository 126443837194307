import React from "react"

import Layout from "./components/Layout"
import GlobalStyles from "./styles/GlobalStyles"

const GlobalStylesProxy = GlobalStyles as any

const App: React.FC = () => {
    return (
        <>
            <Layout />

            <GlobalStylesProxy />
        </>
    )
}

export default App
