import React from "react"

import { Container, Title, ExpendIcon } from "./styles"

interface ServerNameProps {
    name: string
}

const ServerName: React.FC<ServerNameProps> = ({ name }) => {
    return (
        <Container>
            <Title>{name}</Title>

            <ExpendIcon />
        </Container>
    )
}

export default ServerName
