import React from "react"
import {
    ModalView,
    ModalContent,
    ModalHeader,
    ModalTitle,
    ModalBody,
    Logo,
    ModalButton,
    LearnMore,
    LeftFloatBg,
    RightFloatBg,
} from "./styles"

import logo from "../../assets/images/logo-modal.svg"
import bgLeft from "../../assets/images/bg-left.png"
import bgRight from "../../assets/images/bg-right.png"

export interface ModalProps {
    onCloseModal: () => void
}

const WelcomeModal = ({ onCloseModal }: ModalProps) => {
    return (
        <ModalView
            data-view="modal"
            onClick={(e) => {
                if (
                    (e.target as HTMLElement).getAttribute("data-view") ===
                    "modal"
                )
                    onCloseModal()
            }}
        >
            <ModalContent data-view="modal-content">
                <LeftFloatBg
                    src={`${bgLeft}`}
                    alt="Background"
                    className="bg-left"
                />
                <RightFloatBg
                    src={`${bgRight}`}
                    alt="Background"
                    className="bg-right"
                />

                <ModalHeader>
                    <ModalTitle>
                        <small>welcome to</small>
                        <h1>Glime</h1>
                    </ModalTitle>

                    <Logo src={`${logo}`} alt="Glime" />
                </ModalHeader>
                <ModalBody>
                    <p>
                        This is a sandbox environment made for <b>you</b> to
                        test how <b>Glime</b> works.
                    </p>

                    <p>
                        Feel free to access any server of the left panel and
                        make any question. You don't need to invoke Glime, it'll
                        be listening and will answer right away.
                    </p>

                    <p>
                        <i>
                            Also, don't worry, your messages won't be stored
                            anywhere. All sessions are temporary.
                        </i>
                    </p>

                    <ModalButton onClick={() => onCloseModal()}>
                        Start Now
                    </ModalButton>

                    <LearnMore href="https://glimelab.ai" target="_blank">
                        Learn more about Glime
                    </LearnMore>
                </ModalBody>
            </ModalContent>
        </ModalView>
    )
}

export default WelcomeModal
