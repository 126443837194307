import React from "react"
import faker from "faker"

import { Container, Role, User, Avatar, Badge } from "./styles"

interface UserProps {
    nickname: string
    isBot?: boolean
    avatar?: string
    role?: string
    status?: string
}

interface UserListProps {
    members: UserProps[]
}

export interface Props {
    avatar: string
}

const UserRow: React.FC<UserProps> = ({ nickname, isBot, avatar, status }) => {
    return (
        <User>
            <Avatar className={isBot ? "bot" : ""} avatar={avatar ?? ""} />
            <strong>{nickname}</strong>

            <Badge className={status} />

            {isBot && <span>BOT</span>}
        </User>
    )
}

const UserList: React.FC<UserListProps> = ({ members }) => {
    return (
        <Container>
            <Role>
                Online -{" "}
                {members.filter((member) => member.role === "online").length}
            </Role>

            {members
                .filter((member) => member.role === "online")
                .map((member) => (
                    <UserRow
                        nickname={member.nickname}
                        isBot={member.isBot}
                        avatar={member.avatar}
                        status="online"
                    />
                ))
                .sort((a, b) => {
                    if (a.props.nickname > b.props.nickname) {
                        return 1
                    }
                    if (a.props.nickname < b.props.nickname) {
                        return -1
                    }
                    return 0
                })}

            <Role>
                Offline -{" "}
                {members.filter((member) => member.role === "ofline").length}
            </Role>

            {members
                .filter((member) => member.role === "offline")
                .map((member) => (
                    <UserRow
                        nickname={member.nickname}
                        avatar={member.avatar}
                        status="offline"
                    />
                ))
                .sort((a, b) => {
                    if (a.props.nickname > b.props.nickname) {
                        return 1
                    }
                    if (a.props.nickname < b.props.nickname) {
                        return -1
                    }
                    return 0
                })}
        </Container>
    )
}

export default UserList
