import React, { Fragment } from "react"

import ServerButton from "../ServerButton"

import { Container, Separator } from "./styles"

interface ServerListProps {
    servers: any[]
    onServerSelected: (serverId: string) => void
}

const ServerList: React.FC<ServerListProps> = ({
    servers,
    onServerSelected,
}) => {
    return (
        <Container>
            <ServerButton isHome />

            <Separator />

            {servers.map((server) => (
                <div onClick={() => onServerSelected(server.id)}>
                    <ServerButton
                        key={server.id}
                        hasNotifications={server.hasNotifications}
                        mentions={server.mentions}
                        channels={server.channels}
                        image={server.image}
                        selected={true}
                    />
                </div>
            ))}
        </Container>
    )
}

export default ServerList
