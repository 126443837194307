import { FirebaseApp, initializeApp } from "firebase/app"
import {
    getAnalytics,
    logEvent as _logEvent,
    setUserId,
    Analytics,
} from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyDArw2DfR15ia1CZ8Z3AOeQiNXWJaMXFrk",
    authDomain: "gpt-discord-e37f1.firebaseapp.com",
    databaseURL: "https://gpt-discord-e37f1-default-rtdb.firebaseio.com",
    projectId: "gpt-discord-e37f1",
    storageBucket: "gpt-discord-e37f1.appspot.com",
    messagingSenderId: "679073875620",
    appId: "1:679073875620:web:6a41b2324b133139400831",
    measurementId: "G-988Y8VTDNG",
}

let app: FirebaseApp
let analytics: Analytics

const useFirebase = () => {
    const initialize = (sessionId: string) => {
        // if (
        //     window.location.hostname !== "demo.glimelab.ai" &&
        //     window.location.hostname !== "try.glimelab.ai"
        // )
        //     return
        // app = initializeApp(firebaseConfig)
        // analytics = getAnalytics(app)
        // setUserId(analytics, sessionId)
        // return {
        //     app,
        //     analytics,
        // }
    }

    const logEvent = (eventName: string, eventParams: any) => {
        // if (
        //     window.location.hostname !== "demo.glimelab.ai" &&
        //     window.location.hostname !== "try.glimelab.ai"
        // )
        //     return
        // _logEvent(analytics, eventName, eventParams)
    }

    return {
        initialize,
        logEvent,
    }
}

export { useFirebase }
