import React from "react"

import { Button } from "./styles"

import Logo from "../../assets/images/avatars/discord-logo.png"

type Channel = {
    id: string
    name: string
}

export interface Props {
    selected?: boolean
    isHome?: boolean
    hasNotifications?: boolean
    mentions?: number
    channels?: Channel[]
    image?: string
    id?: string
}

const ServerButton: React.FC<Props> = ({
    selected,
    isHome,
    hasNotifications,
    mentions,
    channels,
    image,
}) => {
    return (
        <Button
            isHome={isHome}
            hasNotifications={hasNotifications}
            mentions={mentions}
            className={selected ? "active" : ""}
            channels={channels}
            image={image}
        >
            {isHome && <img src={Logo} alt="Discord" />}
        </Button>
    )
}

export default ServerButton
