import arya from "../../assets/images/avatars/AryaStark.png"
import cersei from "../../assets/images/avatars/Cersei Lanister.png"
import daenerys from "../../assets/images/avatars/Daenerys.png"
import jonSnow from "../../assets/images/avatars/JonSnow.png"
import ned from "../../assets/images/avatars/Ned Stark.png"

import bender from "../../assets/images/avatars/Bender.png"
import phillip from "../../assets/images/avatars/Phillip.png"
import turanga from "../../assets/images/avatars/Turanga.png"
import doctor from "../../assets/images/avatars/Doctor.png"
import nibbler from "../../assets/images/avatars/Nibbler.png"
import zapp from "../../assets/images/avatars/Zapp.png"
import hubert from "../../assets/images/avatars/Hubert.png"

import harry from "../../assets/images/avatars/Harry.png"
import draco from "../../assets/images/avatars/Draco.png"
import ron from "../../assets/images/avatars/Ron.png"
import hermione from "../../assets/images/avatars/Hermione.png"
import snape from "../../assets/images/avatars/Snape.png"
import minerva from "../../assets/images/avatars/Minerva.png"

import seinfeld from "../../assets/images/avatars/Seinfeld.png"
import george from "../../assets/images/avatars/George.png"
import eleine from "../../assets/images/avatars/Eleine.png"
import newman from "../../assets/images/avatars/Newman.png"
import kramer from "../../assets/images/avatars/Kramer.png"
import soup from "../../assets/images/avatars/Soup.png"

import jackie from "../../assets/images/avatars/Jackie.png"

const Glime = {
    nickname: "Glime",
    avatar: "https://cdn.discordapp.com/icons/1109488696991944817/096bec69bc9a5873db971afc7c70cf7e.webp?size=96",
    role: "online",
    isBot: true,
}

const AryaStark = {
    nickname: "Arya Stark",
    avatar: arya,
    role: "online",
    isBot: false,
}

const CerseiLannister = {
    nickname: "Cersei Lannister",
    avatar: cersei,
    role: "online",
    isBot: false,
}

const DaenerysTargaryen = {
    nickname: "Daenerys Targaryen",
    avatar: daenerys,
    role: "online",
    isBot: false,
}

const JonSnow = {
    nickname: "Jon Snow",
    avatar: jonSnow,
    role: "online",
    isBot: false,
}

const NedStark = {
    nickname: "Ned Stark",
    avatar: ned,
    role: "offline",
    isBot: false,
}

// Futurama

const Phillip = {
    nickname: "Phillip J. Fry",
    avatar: phillip,
    role: "online",
    isBot: false,
}

const Bender = {
    nickname: "Bender Bending Rodríguez",
    avatar: bender,
    role: "online",
    isBot: false,
}

const Turanga = {
    nickname: "Turanga Leela",
    avatar: turanga,
    role: "online",
    isBot: false,
}

const Doctor = {
    nickname: "Doctor Zoidberg",
    avatar: doctor,
    role: "offline",
    isBot: false,
}

const Hubert = {
    nickname: "Hubert J. Farnsworth",
    avatar: hubert,
    role: "online",
    isBot: false,
}
const Zapp = {
    nickname: "Zapp Brannigan",
    avatar: zapp,
    role: "offline",
    isBot: false,
}

const Nibbler = {
    nickname: "Nibbler",
    avatar: nibbler,
    role: "online",
    isBot: false,
}

// Harry Potter

const Harry = {
    nickname: "Harry Potter",
    avatar: harry,
    role: "online",
    isBot: false,
}

const Hermione = {
    nickname: "Hermione Granger",
    avatar: hermione,
    role: "online",
    isBot: false,
}

const Ron = {
    nickname: "Ron Weasley",
    avatar: ron,
    role: "offline",
    isBot: false,
}

const Snape = {
    nickname: "Serverus Snape",
    avatar: snape,
    role: "offline",
    isBot: false,
}

const Minerva = {
    nickname: "Minerva McGonagall",
    avatar: minerva,
    role: "online",
    isBot: false,
}

const Draco = {
    nickname: "Draco Malfoy",
    avatar: draco,
    role: "online",
    isBot: false,
}

// Seiunfeld

const Seinfeld = {
    nickname: "Jerry Seinfeld",
    avatar: seinfeld,
    role: "online",
    isBot: false,
}

const Eleine = {
    nickname: "Eleine Benes",
    avatar: eleine,
    role: "online",
    isBot: false,
}

const Kramer = {
    nickname: "Cosmo Kramer",
    avatar: kramer,
    role: "offline",
    isBot: false,
}

const Newman = {
    nickname: "Newman",
    avatar: newman,
    role: "online",
    isBot: false,
}

const George = {
    nickname: "George Costanza",
    avatar: george,
    role: "offline",
    isBot: false,
}

const Soup = {
    nickname: "Soup Nazi",
    avatar: soup,
    role: "offline",
    isBot: false,
}

// The user

const JackieRobinson = {
    nickname: "Jackie Robinson",
    avatar: jackie,
    role: "online",
    isBot: false,
}

// export all members
export {
    Glime,
    AryaStark,
    CerseiLannister,
    DaenerysTargaryen,
    JonSnow,
    NedStark,
    Phillip,
    Bender,
    Turanga,
    Doctor,
    Hubert,
    Zapp,
    Nibbler,
    Harry,
    Hermione,
    Ron,
    Snape,
    Minerva,
    Draco,
    Seinfeld,
    Eleine,
    Kramer,
    Newman,
    George,
    Soup,
    JackieRobinson,
}
