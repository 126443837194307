import React from "react"

import { Container, HashtagIcon, InviteIcon, SettingsIcon } from "./styles"
import styled from "styled-components"

export interface Props {
    channelName: string
    selected?: boolean
}

export const IconsWrapper = styled.div`
    opacity: 0;
`

export const StyledContainer = styled(Container)`
    &:hover ${IconsWrapper} {
        opacity: 1;
    }
`

const ChannelButton: React.FC<Props> = ({ channelName, selected }) => {
    return (
        <StyledContainer className={selected ? "active" : ""}>
            <div>
                <HashtagIcon />
                <span>{channelName}</span>
            </div>

            <IconsWrapper>
                <InviteIcon />
                <SettingsIcon />
            </IconsWrapper>
        </StyledContainer>
    )
}

export default ChannelButton
