import styled from "styled-components"

import { MessageProps } from "."

export const Container = styled.div`
    display: flex;
    align-items: flex-start;

    padding: 4px 16px;
    margin-right: 4px;

    a {
        color: white;
        decoration: underline;
    }

    background-color: transparent;

    &.mention {
        background-color: var(--mention-message);

        border-left: 2px solid var(--mention-detail);
        padding-left: 14px;
    }

    & + div {
        margin-top: 13px;
    }

    & + div.headless {
        margin-top: 0px;
    }
`

export const Avatar = styled.div<MessageProps>`
    width: 40px;
    height: 40px;

    background-color: var(--secondary);
    border-radius: 50%;

    &.bot {
        background-color: var(--mention-detail);
    }
    background-image: url("${(props) => props.avatar}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export const Message = styled.div`
    min-height: 40px;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 17px;

    &.headless {
    }

    & p:nth-child(n + 2) {
        padding: 10px 0px;
    }

    & pre > code > p {
        padding: 0px !important;
    }

    & pre {
        margin: 10px 0px;
    }

    & pre > div {
        color: red;
        padding: 10px;
        background-color: #242628;
        color: #c5c8c6;
        font-size: 14px;
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;

    > strong {
        color: var(--white);
        font-size: 16px;
    }

    > span {
        margin-left: 6px;

        background-color: var(--discord);
        color: var(--white);
        border-radius: 4px;
        padding: 4px 5px;

        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
    }

    > time {
        margin-left: 6px;
        color: var(--gray);
        font-size: 13px;
    }
`

export const Content = styled.div`
    text-align: left;
    font-size: 16px;
    color: var(--white);
`

export const Mention = styled.span`
    color: var(--link);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`
